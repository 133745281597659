export default {
  translation: {
    'Track Earthquakes': 'Depremleri Takip Edin',
    City: 'Şehir',
    "There aren't any earthquakes": 'Hiç deprem yok',
    'Search a place': 'Bir yer ara',
    'Occurred a problem while loading the page': 'Sayfa yüklenirken bir problem oluştu',
    'Occurred a problem': 'Bir hata meydana geldi',
    Time: 'Zaman',
    or: 'ya da',
    'Start of Date': 'Başlangıç Zamanı',
    'End of Date': 'Bitiş Tarihi',
    REMOVE: 'TEMİZLE',
    Magnitude: 'Büyüklük',
    Time: 'Zaman',
    'Leave From Fullscreen': 'Tam Ekrandan Çık',
    'Switch to Fullscreen': 'Tam Ekrana Geç',
    'Type of Map': 'Harita Tipi',
    'PAST EARTHQUAKES': 'GEÇMİŞ DEPREMLER',
    FILTERS: 'FİLTRELER',
    SETTINGS: 'AYARLAR',
    minutes: 'saniye',
    'Last {hourCount} hour': 'Son {hourCount} saat',
    'Last {dayCount} day': 'Son {dayCount} gün',
    'Last {dayMonth} month': 'Son {dayMonth} ay',
    Today: 'Bugün',
    'from {sourceInfo}': '{sourceInfo} kaynağından',
    Language: 'Dil',
    Depth: 'Derinlik',
    All: 'Tümü',
    Sources: 'Kaynaklar',
    'Earthquake Magnitude': 'Deprem Büyüklüğü',
    'Live Earthquake': 'Canlı Deprem',
    Info: 'Bilgi',
    Anywhere: 'Herhangi bir yer',
    'Fault Line Active': 'Fay Hattı Aktif',
    Layer: 'Katman',
    Name: 'İsim',
    'Plate A': 'Plaka A',
    'Plate B': 'Plaka B',
    Source: 'Kaynak',
    'Fault Line': 'Fay Hattı',
    'Warn with sound when a new earthquake occurs': 'Yeni bir deprem meydana geldiğinden ses ile uyar',
    ANIMATION: 'ANİMASYON',
    Range: 'Aralık',
    '{minCount} minutes': '{minCount} dakika',
    '{hoursCount} hours': '{hoursCount} saat',
    '1 hour': '1 saat',
    '1 day': '1 gün',
    'Occurred a problem': 'Bir hata meydana geldi',
    start: 'başlat',
    stop: 'durdur',
    clear: 'temizle',
    continue: 'devam et',
    'again start': 'tekrar başlat',
    animation: 'animasyon',
    'past earthquakes': 'geçmiş depremler',
    filters: 'filtreler',
    settings: 'ayarlar',
    'Population Density': 'Popülasyon Yoğunluğu',
    'Each level of earthquake magnitude has different color': 'Her deprem büyüklüğü seviyesi farklı renge sahiptir',
    'Flashing animation': 'Yanıp sönen animasyon',
    'Red line': 'Kırmzıı çizgi',
    'Different colors according to density': 'Yoğunluğa göre farklı renkler',
    'Earthquake Source Color': 'Deprem Kaynak Rengi',
    'Each source of earthquake has different color to detect': 'Her deprem kaynağının tespit edilmesi için farklı bir rengi vardır',
    Welcome: 'Hoşgeldin',
    'Interactive earthquake map you can see live and past earthquakes':
      'Canlı ve geçmiş depremleri inceleyebileceğiniz etkileşimli deprem haritası',
    Animation: 'Animasyon',
    'You can see the earthquakes between the dates you selected as animations piece by piece':
      'Seçilen tarihle arasındaki depremleri animasyonlu bir şekilde parça parça inceleyebilirsiniz',
    'You can see earthquakes that are in between the dates you want': 'Seçtiğiniz tarihler arasındaki depremleri görebilirsiniz',
    Filters: 'Filtreler',
    'You can filter the earthquakes with your needs such as mag, depth':
      'Mag, derinlik gibi ihtiyaçlarınıza göre depremleri filtreleyebilirsiniz',
    Settings: 'Ayarlar',
    'You can change settings such as the type of map, notification and the status of features':
      'Harita türü, bildirim ve özelliklerin durumu gibi ayarları değiştirebilirsiniz',
    'Search a Place': 'Bir Yer Arama',
    'You can focus anywhere you want': 'İstediğiniz yere odaklanabilirsiniz',
    'Earthquake List': 'Deprem Listesi',
    'You can see all earthquakes as a list': 'Tüm depremleri bir liste olarak görebilirsin',
    Info: 'Bilgi',
    'You can understand some colors/shapes by using this button': 'Bu butonu kullanarak bazı renkleri/şekilleri anlayabilirsiniz',
    'Sign out': 'Çıkış yap',
    'AI Chat': 'AI Sohbet',
    'Login required': 'Oturum açmak gerekli',
    'No messages yet. You can ask anything related to earthquakes!': 'Hiç mesaj yok. Depremle ilgili her şeyi sorabilirsiniz!',
    Type: 'Tip',
    'Type a question': 'Bir soru yaz',
    Send: 'Gönder',
    "You don't have enough limit": 'Yeterli limitiniz yok',
    'You can ask anything related to earthquakes': 'Depremle ilgili her şeyi sorabilirsiniz',
    'You can ask anything related to earthquakes that occurred in the past. For now, it is only valid for a day':
      'Geçmişte meydana gelen depremlerle ilgili her şeyi sorabilirsiniz. Şimdilik sadece bir gün için geçerlidir',
    Example: 'Örnek',
    'What does depth mean in an earthquake': 'Depremde derinlik ne anlama gelir',
    'What was the biggest occurred earthquake on 8 June 2023': '8 Haziran 2023"te meydana gelen en büyük deprem hangisiydi',
    "You have tokens to ask questions. You can't ask a question after the tokens run out":
      'Soru sormak için jetonlarınız var. Jetonlar bittikten sonra soru soramazsınız',
    Share: 'Paylaş',
    'Copied!': 'Kopyalandı!',
    'New Earthquake': 'Yeni Deprem',
    Earthquake: 'Deprem',
    Location: 'Konum',
    'Newest First': 'Yakın Tarihli',
    'Oldest First': 'Uzak Tarihli',
    'Largest Magnitude First': 'Büyük Olana Göre',
    'Smallest Magnitude First': 'Küçük Olana Göre',
    Sorting: 'Sıralama',
    'Total of {count} earthquakes': 'Toplam {count} deprem',
    Loading: 'Yükleniyor',
    'Maximum of 2 months date range can be selected': 'Maksimum 2 aylık tarih aralığı seçilebilir',
    'Something went wrong': 'Bir şeyler yanlış gitti',
    'We are working on it and we will get it fixed as soon as possible':
      'Bunun üzerinde çalışıyoruz ve mümkün olan en kısa sürede düzelteceğiz',
    Help: 'Yardım',
    Guide: 'Kılavuz',
    General: 'Genel',
    'This project is open source': 'Bu proje açık kaynaktır',
  },
}

export default {
  translation: {
    'Track Earthquakes': 'Track Earthquakes',
    City: 'City',
    "There aren't any earthquakes": "There aren't any earthquakes",
    'Search a place': 'Search a place',
    'Occurred a problem while loading the page': 'Occurred a problem while loading the page',
    'Occurred a problem': 'Occurred a problem',
    Time: 'Time',
    or: 'or',
    'Start of Date': 'Start of Date',
    'End of Date': 'End of Date',
    REMOVE: 'REMOVE',
    Magnitude: 'Magnitude',
    Time: 'Time',
    'Leave From Fullscreen': 'Leave From Fullscreen',
    'Switch to Fullscreen': 'Switch to Fullscreen',
    'Type of Map': 'Type of Map',
    'PAST EARTHQUAKES': 'PAST EARTHQUAKES',
    FILTERS: 'FILTERS',
    SETTINGS: 'SETTINGS',
    minutes: 'minutes',
    'Last {hourCount} hour': 'Last {hourCount} hour',
    'Last {dayCount} day': 'Last {dayCount} day',
    'Last {dayMonth} month': 'Last {dayMonth} month',
    Today: 'Today',
    '{kmCount} km deep': '{kmCount} km deep',
    'from {sourceInfo}': 'from {sourceInfo}',
    Language: 'Language',
    Depth: 'Depth',
    All: 'All',
    Sources: 'Sources',
    'Earthquake Magnitude': 'Earthquake Magnitude',
    'Live Earthquake': 'Live Earthquake',
    Info: 'Info',
    Anywhere: 'Anywhere',
    'Fault Line Active': 'Fault Line Active',
    Layer: 'Layer',
    Name: 'Name',
    'Plate A': 'Plate A',
    'Plate B': 'Plate B',
    Source: 'Source',
    'Fault Line': 'Fault Line',
    'Warn with sound when a new earthquake occurs': 'Warn with sound when a new earthquake occurs',
    ANIMATION: 'ANIMATION',
    Range: 'Range',
    '{minCount} minutes': '{minCount} minutes',
    '{hoursCount} hours': '{hoursCount} hours',
    '1 hour': '1 hour',
    '1 day': '1 day',
    'Occurred a problem': 'Occurred a problem',
    start: 'start',
    stop: 'stop',
    clear: 'clear',
    continue: 'continue',
    'again start': 'again start',
    animation: 'animation',
    'past earthquakes': 'past earthquakes',
    filters: 'filters',
    settings: 'settings',
    'Population Density': 'Population Density',
    'Each level of earthquake magnitude has a different color': 'Each level of earthquake magnitude has a different color',
    'Flashing animation': 'Flashing animation',
    'Red line': 'Red line',
    'Different colors according to density': 'Different colors according to density',
    'Earthquake Source Color': 'Earthquake Source Color',
    'Each source of earthquake has different color to detect': 'Each source of earthquake has different color to detect',
    Welcome: 'Welcome',
    'Interactive earthquake map you can see live and past earthquakes': 'Interactive earthquake map you can see live and past earthquakes',
    Animation: 'Animation',
    'You can see the earthquakes between the dates you selected as animations piece by piece':
      'You can see the earthquakes between the dates you selected as animations piece by piece',
    'Past Earthquake': 'Past Earthquake',
    'You can see earthquakes that are in between the dates you want': 'You can see earthquakes that are in between the dates you want',
    Filters: 'Filters',
    'You can filter the earthquakes with your needs such as mag, depth':
      'You can filter the earthquakes with your needs such as mag, depth',
    Settings: 'Settings',
    'You can change settings such as the type of map, notification and the status of features':
      'You can change settings such as the type of map, notification and the status of features',
    'Search a Place': 'Search a Place',
    'You can focus anywhere you want': 'You can focus anywhere you want',
    'Earthquake List': 'Earthquake List',
    'You can see all earthquakes as a list': 'You can see all earthquakes as a list',
    Info: 'Info',
    'You can understand some colors/shapes by using this button': 'You can understand some colors/shapes by using this button',
    'Sign out': 'Sign out',
    'AI Chat': 'AI Chat',
    'Login required': 'Login required',
    'No messages yet. You can ask anything related to earthquakes!': 'No messages yet. You can ask anything related to earthquakes!',
    Type: 'Type',
    'Type a question': 'Type a question',
    Send: 'Send',
    "You don't have enough limit": "You don't have enough limit",
    'You can ask anything related to earthquakes': 'You can ask anything related to earthquakes',
    'You can ask anything related to earthquakes that occurred in the past. For now, it is only valid for a day':
      'You can ask anything related to earthquakes that occurred in the past. For now, it is only valid for a day',
    Example: 'Example',
    'What does depth mean in an earthquake': 'What does depth mean in an earthquake',
    'What was the biggest occurred earthquake on 8 June 2023': 'What was the biggest occurred earthquake on 8 June 2023',
    "You have tokens to ask questions. You can't ask a question after the tokens run out":
      "You have tokens to ask questions. You can't ask a question after the tokens run out",
    Share: 'Share',
    'Copied!': 'Copied!',
    'New Earthquake': 'New Earthquake',
    Earthquake: 'Earthquake',
    Location: 'Location',
    'Newest First': 'Newest First',
    'Oldest First': 'Oldest First',
    'Largest Magnitude First': 'Largest Magnitude First',
    'Smallest Magnitude First': 'Smallest Magnitude First',
    Sorting: 'Sorting',
    'Total of {count} earthquakes': 'Total of {count} earthquakes',
    Loading: 'Loading',
    'Maximum of 2 months date range can be selected': 'Maximum of 2 months date range can be selected',
    'Something went wrong': 'Something went wrong',
    'We are working on it and we will get it fixed as soon as possible':
      'We are working on it and we will get it fixed as soon as possible',
    Help: 'Help',
    Guide: 'Guide',
    General: 'General',
    'Open Source': 'Open Source',
    'This project is open source': 'This project is open source',
  },
}
